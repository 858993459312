@use "@shopliftr/common-ng" as common;
@use "../assets/css/assets-theme";

@mixin all-component-themes($theme) {

    @include assets-theme.theme;
}

.mgd-admin-priority {

    @include common.rounded();

    color: common.$font-color-contrast;
    display: inline-block;
    font-size: common.$font-size-small;
    font-weight: common.$font-weight-semibold;
    margin: 0 4px 0 0;
    text-align: center;
    width: 21px;

    &.priority-1 {
        background-color: common.$color-priority-1;
    }

    &.priority-2 {
        background-color: common.$color-priority-2;
    }

    &.priority-3 {
        background-color: common.$color-priority-3;
    }

    &.priority-4 {
        background-color: common.$color-priority-4;
    }

    &.cancelled,
    &.rejected {
        color: common.$color-status-immediate;
    }
}
