@use "@shopliftr/common-ng"as common;

@mixin theme {

    /**
     * Listed Items Table
     */
    .product-image {
        max-height: 40px;
        max-width: 100%;
        display: block;
    }

    .item-row {
        border-bottom: common.$border;
        padding: 5px 0px;

        &:nth-child(even) {
            background-color: common.$color-background-lighter;
        }

        &.single-deal-row:hover {
            cursor: pointer;
        }

        &.item-row-header {
            color: common.$color-accent-dark;
            background-color: white;
            font-weight: common.$font-weight-semibold;
            overflow: hidden;
        }

        &.selected-row {
            background-color: common.$color-background-base;
        }
    }

    .item-column {
        float: left;
        padding: 5px 1%;
        word-wrap: break-word;
    }
}