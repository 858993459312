@use "@shopliftr/common-ng" as common;

@mixin theme {

    html {
        min-width: 1300px;
    }

    [hidden] {
        display: none !important;
    }

    /**
     * FORM COMPONENTS
     */
    .operator-toggle-button {
        &[disabled] {

            .operator-bottom,
            .operator-top {
                border-bottom: 1px solid common.$color-border-light;
            }
        }

        .operator-bottom,
        .operator-top {
            border-bottom: 1px solid common.$color-border-base;
            display: block;
            height: 1px;
            margin: 5px auto;
            width: 10px;
            -webkit-transition: transform 300ms ease-in-out;
            transition: transform 300ms ease-in-out;
        }

        &.eq {
            .operator-bottom {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            .operator-top {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
        }

        &.gte {
            .operator-bottom {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            .operator-top {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        &.lte {
            .operator-bottom {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            .operator-top {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }

    /**
     * PRELOAD ANIMATION
     */
    .preloader-container {
        background-color: common.$color-background-base;
        min-height: 250px;
        padding-top: 85px;
        @include common.rounded();
    }

    .loader,
    .loader::after,
    .loader::before {
        border-radius: 50%;
        height: 2.5em;
        width: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
    }

    .loader {
        position: relative;
        color: common.$color-background-base;
        font-size: 10px;
        margin: 0 auto;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);

        &::after,
        &::before {
            position: absolute;
            top: 0;
            content: '';
        }

        &:after {
            left: 3.5em;
            -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
        }

        &:before {
            left: -3.5em;
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
        }

        &.deal-count-loader {
            top: 50%;
            color: common.$color-text-disabled;
            font-size: 2px;
            -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
        }
    }

    @-webkit-keyframes load7 {

        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }

        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }

    @keyframes load7 {

        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }

        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
}