@mixin theme {

    @page {
        size: landscape;
    }

    @media print {

        //App Component
        html,
        body {
            min-width: 100% !important;
            height: auto !important;
        }

        section {
            border: 0 !important;
            margin: 50px 0 0 0 !important;
        }

        .secondary-nav:not(.active) {
            display: none;
        }

        //Site Menu
        .site-menu-component {
            .module-site-navigation {
                display: none !important;
            }
        }

        //Admin Component
        .admin-component {
            .admin-main-navigation {
                display: none !important;
            }

            .main-content-container {
                padding: 0 !important;
            }

            .mgd-layout-main-content-wrapper {
                padding-top: 0 !important;
            }
        }

        //Supervisor Dashboard
        .team-dashboard-component {

            .dashboard-charts-container,
            .secondary-nav-wrapper,
            .prefill-filters {
                display: none !important;
            }

            .headings {
                padding: 20px 0 0 !important;
            }
        }

        //Task List {
        .task-list-component {

            .mgd-list-filter-search,
            .lazy-load-container {
                display: none !important;
            }
        }

        .task-view-component {
            .column-task-actions-wrapper {
                display: none !important;
            }

            .task-name {
                padding-right: 25px;

                .related-task-count {
                    right: 0px;
                    position: absolute;
                    top: 0px;
                }
            }
        }
    }
}